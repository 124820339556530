export const hasLogo = [
    "FEDERACION_MEX_DEV",
    "FEDERACION_MEX",
    "CIE_OCESA_DEV",
    "CIE_OCESA",
    "RELIANCE_DEV",
    "RELIANCE",
]

export const hasFixedContractor = {
    "FEDERACION_MEX_DEV": "Federación Mexicana de Ingenieros Civiles",
    "FEDERACION_MEX": "Federación Mexicana de Ingenieros Civiles",
    "CIE_OCESA_DEV": "OCESA",
    "CIE_OCESA": "OCESA",
}